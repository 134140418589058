/**
 * Msa Duty Service UI
 * REST API for Msa Duty Service
 *
 * The version of the OpenAPI document: 1.3.2
 * Contact: dimilar-devops@bit.admin.ch
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export enum DutyDtoStateEnum {
    Open = 'OPEN',
    Canceled = 'CANCELED',
    Done = 'DONE',
    Planned = 'PLANNED'
}

