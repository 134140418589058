/**
 * Msa Duty Service UI
 * REST API for Msa Duty Service
 *
 * The version of the OpenAPI document: 1.3.2
 * Contact: dimilar-devops@bit.admin.ch
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export enum DutyMsaStateDto {
    Planned = 'PLANNED',
    ServiceNoticeSent = 'SERVICE_NOTICE_SENT',
    CallOfDutySent = 'CALL_OF_DUTY_SENT',
    Completed = 'COMPLETED',
    Booked = 'BOOKED'
}

