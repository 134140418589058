/**
 * Msa Duty Service UI
 * REST API for Msa Duty Service
 *
 * The version of the OpenAPI document: 1.3.2
 * Contact: dimilar-devops@bit.admin.ch
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export enum Prevention {
    DvsDutyTypeNotSupported = 'DVS_DUTY_TYPE_NOT_SUPPORTED',
    AlreadyHasRequest = 'ALREADY_HAS_REQUEST',
    LeaveTooFarInFuture = 'LEAVE_TOO_FAR_IN_FUTURE'
}

