<div class="msa-table tw-pb-60" data-cy="requestsPage">
  <h1 class="msa-text-h1 tw-my-30 d:tw-my-60">
    {{ "i18n.requests.headers.header" | safeTranslate }}
  </h1>

  @if (errorMessage(); as error) {
    <app-msa-content-notice [type]="message.Warning"> {{ error | translate }}</app-msa-content-notice>
    <div class="tw-mb-30 d:tw-mb-60"></div>
  } @else {
    <!-- Header -->
    <div class="tw-hidden tw-grid-cols-6 tw-pb-9 tw-ps-24 tw-text-black-light-5 d:tw-grid d:tw-gap-18">
      <dd>{{ "i18n.requests.headers.requestType" | safeTranslate }}</dd>
      <dd>{{ "i18n.requests.headers.duty" | safeTranslate }}</dd>
      <dd>{{ "i18n.requests.headers.start" | safeTranslate }}</dd>
      <dd>{{ "i18n.requests.headers.submitted" | safeTranslate }}</dd>
      <dd>{{ "i18n.requests.headers.state" | safeTranslate }}</dd>
      <dd class="tw-flex tw-justify-end">{{ "i18n.requests.headers.actions" | safeTranslate }}</dd>
    </div>

    <div class="tw-grid tw-grid-cols-1" data-cy="requestsTable">
      @for (requestView of requestViews(); track requestView.id) {
        <!-- Desktop View -->
        <div
          class="msa-text tw-hidden tw-cursor-pointer tw-grid-cols-6 tw-items-center tw-bg-black-light-1 tw-ps-24 hover:tw-bg-black-light-2 d:tw-grid d:tw-gap-18"
          [class.tw-bg-black-light-2]="requestView.type === RequestType.Reconsideration"
          [class.top-spacer]="requestView.type !== RequestType.Reconsideration"
          [routerLink]="requestView.clickRouterLink"
          [attr.data-id]="requestView.id"
        >
          <div class="tw-font-bold">{{ requestView.typeText | translate }}</div>
          <div>{{ requestView.dutyType ? (requestView.dutyType | translateObject) : "" }}</div>
          <div>{{ requestView.from }}</div>
          <div>{{ requestView.submittedAt }}</div>
          <div class="tw-flex tw-flex-wrap tw-items-center tw-gap-x-12">
            <msa-status-pill
              [text]="requestView.stateTextAndColor.statusText"
              [backgroundClass]="requestView.stateTextAndColor.backgroundColor"
            />
            @if (requestView.state !== RequestState.Incomplete) {
              <div class="msa-text-small">
                {{ requestView.trackingState | safeTranslate }}
              </div>
            }
          </div>
          <div class="tw-flex tw-justify-end">
            <msa-actions-menu [actions]="requestView.actions"></msa-actions-menu>
          </div>
        </div>

        <!-- Mobile View -->
        @if (requestView.type !== RequestType.Reconsideration && !$first) {
          <div class="tw-mb-6 tw-block d:tw-hidden"></div>
        }

        <div
          class="msa-text-small tw-relative tw-block tw-cursor-pointer tw-bg-black-light-1 tw-px-21 tw-py-15 hover:tw-bg-black-light-2 d:tw-hidden"
          [class.tw-bg-black-light-2]="requestView.type === RequestType.Reconsideration"
          [routerLink]="requestView.clickRouterLink"
          [attr.data-id]="requestView.id"
        >
          <p class="msa-text tw-mb-9 tw-font-bold">{{ requestView.typeText | safeTranslate }}</p>
          <div class="tw-flex tw-flex-col tw-gap-6">
            <p>{{ requestView.dutyType ? (requestView.dutyType | translateObject) : "" }} {{ requestView.from }}</p>
            <div class="tw-flex tw-gap-12">
              <span class="tw-text-black-light-5.5">{{ "i18n.requests.headers.submitted" | safeTranslate }}:</span>
              {{ requestView.submittedAt }}
            </div>
            <div class="tw-flex tw-flex-wrap tw-items-center tw-gap-x-12">
              <span class="tw-text-black-light-5.5">{{ "i18n.requests.headers.state" | safeTranslate }}:</span>
              <msa-status-pill
                [text]="requestView.stateTextAndColor.statusText"
                [backgroundClass]="requestView.stateTextAndColor.backgroundColor"
              />
              @if (requestView.state !== RequestState.Incomplete) {
                <div class="tracking-status">
                  {{ requestView.trackingState | safeTranslate }}
                </div>
              }
            </div>
          </div>

          <msa-actions-menu [actions]="requestView.actions"></msa-actions-menu>
        </div>

        @if (requestView.state == RequestState.Incomplete) {
          <div class="tw-flex tw-items-center tw-gap-9 tw-bg-blue tw-px-15 tw-py-12">
            <div>
              <mat-icon>info_outline</mat-icon>
            </div>
            {{ "i18n.requests.headers.incomplete-info" | safeTranslate }}
          </div>
        }
      } @empty {
        <msa-empty-list-box message="i18n.requests.empty" />
      }
    </div>
  }
</div>
