<label class="msa-text tw-mb-6" [appMarkRequired]="hcd.control()">{{ label() | safeTranslate }}</label>
<mat-form-field class="tw-w-full" appearance="outline" subscriptSizing="dynamic">
  <input
    [formControl]="hcd.control()"
    [ngxTimepicker]="timePicker"
    [format]="24"
    data-cy="time"
    matInput
    placeholder="hh:mm"
  />
  <mat-icon (click)="timePicker.open()" matSuffix>schedule</mat-icon>

  <ngx-material-timepicker
    #timePicker
    [theme]="darkTheme"
    [cancelBtnTmpl]="cancelBtn"
    [confirmBtnTmpl]="confirmBtn"
    [ESC]="true"
  />

  <ng-template #cancelBtn>
    <div class="msa-text-small tw-me-12">
      <msa-button color="secondary">{{ cancelButtonText() | safeTranslate }}</msa-button>
    </div>
  </ng-template>
  <ng-template #confirmBtn>
    <div class="msa-text-small">
      <msa-button color="primary">{{ confirmButtonText() | safeTranslate }}</msa-button>
    </div>
  </ng-template>

  <mat-error *msaFormErrors="hcd.control()" />
</mat-form-field>
