/**
 * Msa Duty Service UI
 * REST API for Msa Duty Service
 *
 * The version of the OpenAPI document: 1.3.2
 * Contact: dimilar-devops@bit.admin.ch
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Allowed public transportation class
 */
export enum PublicTransportClass {
    FirstClass = 'FIRST_CLASS',
    SecondClass = 'SECOND_CLASS'
}

