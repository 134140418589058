import {DestroyRef, Injectable} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {Router} from '@angular/router';
import {Action, State, StateContext, Store} from '@ngxs/store';
import {BottomBarService} from '@shared/shared-module/services/bottom-bar-service/bottom-bar.service';
import {ErrorMessageHandler} from '@shared/shared-module/services/error-message-handler/error-message.handler';
import {cloneDeep, isArray, mergeWith} from 'lodash';
import {EMPTY, Observable, catchError, take, tap} from 'rxjs';
import {
  DetailsShiftFdtDto,
  DetailsShiftFdtUniversityDto,
  DivisionScheduleDto,
  DocumentDto,
  DocumentsRestService,
  DutiesRestService,
  LeaveRequestRestService,
  RequestDto,
  RequestDtoRequestDetail,
  ShiftRequestRestService
} from '../../core/api/generated/msa-admin-query';
import {DocumentService} from '../../services/document.service';
import {
  FetchDepartmentCodesForUniversity,
  FetchReasonTypeCodeList,
  FetchUniversityNameCodeList,
  ResetUniversityDepartments
} from '../actions/code-list.state.actions';
import {
  CloseRequest,
  CreateLeaveRequest,
  CreateReconsideration,
  CreateShiftRequest,
  DiscardUserFormData,
  DownloadAttachmentDocument,
  DownloadResponseDocument,
  FetchDivisionSchedule,
  RemoveDocument,
  SaveDraft,
  SubmitRequest,
  UpdateRequestDocuments,
  UpdateUserFormData
} from '../actions/edit-request.state.actions';
import {FetchRequest, OpenRequest, UpdateRequest} from '../actions/requests.state.actions';
import {EditRequestStateModel} from '../models/edit-request-state.model';
import {EditRequestStateSelectors} from '../selectors/edit-request-state.selectors';
import {RequestsOverviewStateSelectors} from '../selectors/requests-overview.state.selectors';
import {RequestsStateSelectors} from '../selectors/requests.state.selectors';

export const DFLT_EDIT_SHIFT_REQUEST_STATE: EditRequestStateModel = {
  requestId: null,
  userFormData: null,
  isRightAfterSubmit: false,
  divisionSchedules: []
};

@State<EditRequestStateModel>({
  name: 'editRequest',
  defaults: DFLT_EDIT_SHIFT_REQUEST_STATE
})
@Injectable()
export class EditRequestState {
  constructor(
    private shiftRequestRestService: ShiftRequestRestService,
    private leaveRequestRestService: LeaveRequestRestService,
    private documentsRestService: DocumentsRestService,
    private dutiesRestService: DutiesRestService,
    private errorMessageHandler: ErrorMessageHandler,
    private bottomBarService: BottomBarService,
    private documentService: DocumentService,
    private store: Store,
    private router: Router,
    private destroyRef: DestroyRef
  ) {}

  @Action(CreateShiftRequest)
  createShiftRequest(ctx: StateContext<EditRequestStateModel>, {dutyId}: CreateShiftRequest): Observable<RequestDto> {
    return this.shiftRequestRestService.createShiftRequest({uuid: dutyId}).pipe(
      take(1),
      tap(requestDto => this.router.navigateByUrl(`/admin-query/requests/shift/${requestDto.id}`)),
      catchError(response => this.errorMessageHandler.logAndIgnore(response)),
      takeUntilDestroyed(this.destroyRef)
    );
  }

  @Action(CreateLeaveRequest)
  createLeaveRequest(ctx: StateContext<EditRequestStateModel>, {dutyId}: CreateShiftRequest): Observable<RequestDto> {
    return this.leaveRequestRestService.createLeaveRequest({uuid: dutyId}).pipe(
      take(1),
      tap(requestDto => this.router.navigateByUrl(`/admin-query/requests/leave/${requestDto.id}`)),
      catchError(response => this.errorMessageHandler.logAndIgnore(response)),
      takeUntilDestroyed(this.destroyRef)
    );
  }

  @Action(UpdateUserFormData)
  updateRequestDetail(ctx: StateContext<EditRequestStateModel>, {requestDetail}: UpdateUserFormData) {
    const presentRequestDetail = ctx.getState().userFormData;
    const requestDetailType = this.store.selectSnapshot(EditRequestStateSelectors.getCurrentEditingRequest)
      ?.requestDetail._type;
    if (!requestDetail || !requestDetailType) return;

    ctx.patchState({
      // cloneDeep: object from store are frozen, therefore we need the cloneDeep() here
      userFormData: mergeWith(
        {
          ...cloneDeep(presentRequestDetail),
          _type: requestDetailType
        },
        requestDetail,
        (a, b) => (isArray(b) ? b : undefined)
      ) as RequestDtoRequestDetail
    });
  }

  @Action(DiscardUserFormData)
  discardUserFormData(ctx: StateContext<EditRequestStateModel>) {
    const {requestId} = ctx.getState();
    const request = this.store.selectSnapshot(RequestsStateSelectors.getRequestByIdFn)(requestId!);

    ctx.patchState({
      userFormData: request?.requestDetail
    });
  }

  @Action(CreateReconsideration)
  createReconsideration(ctx: StateContext<EditRequestStateModel>, {requestId}: CreateReconsideration): Observable<any> {
    this.closeShiftRequest(ctx);

    return this.shiftRequestRestService.createReconsiderationRequest(requestId).pipe(
      take(1),
      tap(requestDto => this.router.navigateByUrl(`/admin-query/requests/shift/${requestDto.id}`)),
      catchError(response => this.errorMessageHandler.logAndIgnore(response)),
      takeUntilDestroyed(this.destroyRef)
    );
  }

  @Action(SubmitRequest)
  submitShiftRequest(ctx: StateContext<EditRequestStateModel>): Observable<RequestDto> {
    const {requestId, userFormData} = ctx.getState();
    if (!userFormData) throw new Error('User form data must not be null!');
    if (!requestId) throw new Error('Request ID must not be null!');

    let submitObservable: Observable<RequestDto>;
    if (EditRequestStateSelectors.isLeaveDetail(userFormData)) {
      submitObservable = this.leaveRequestRestService.submitLeaveRequest(requestId);
    } else {
      submitObservable = this.shiftRequestRestService.submitShiftRequest(requestId);
    }

    return submitObservable.pipe(
      tap(() => ctx.patchState({isRightAfterSubmit: true})),
      tap(request => ctx.dispatch(new UpdateRequest(request))),
      catchError(response => {
        ctx.patchState({isRightAfterSubmit: false});
        return this.errorMessageHandler.logAndThrow(response);
      }),
      takeUntilDestroyed(this.destroyRef)
    );
  }

  @Action(OpenRequest)
  openRequest(ctx: StateContext<EditRequestStateModel>, {requestId, requestType}: OpenRequest) {
    return ctx.dispatch(new FetchRequest(requestId, requestType)).pipe(
      tap(() => {
        const request = this.store.selectSnapshot(RequestsStateSelectors.getRequestByIdFn)(requestId)!;
        ctx.patchState({
          requestId,
          userFormData: request.requestDetail
        });

        return this.loadRequestFormResources(ctx, request.requestDetail);
      })
    );
  }

  @Action(SaveDraft)
  saveDraft(ctx: StateContext<EditRequestStateModel>): Observable<RequestDto> {
    const {requestId, userFormData} = ctx.getState();
    if (!requestId || !userFormData) return EMPTY;

    let saveObservable: Observable<RequestDto>;
    if (EditRequestStateSelectors.isLeaveDetail(userFormData)) {
      saveObservable = this.leaveRequestRestService.saveLeaveRequest(requestId, userFormData);
    } else {
      //TODO DMLRINF-1605 support GAD as well
      saveObservable = this.shiftRequestRestService.saveShiftRequest(
        requestId,
        userFormData as DetailsShiftFdtDto | DetailsShiftFdtUniversityDto
      );
    }

    return saveObservable.pipe(
      tap(() => {
        this.bottomBarService.show({text: 'i18n.common.will-be-saved', icon: 'cloud'});
      }),
      tap(request => this.store.dispatch(new UpdateRequest(request))),
      catchError(error => this.errorMessageHandler.logAndIgnore(error)),
      takeUntilDestroyed(this.destroyRef)
    );
  }

  @Action(DiscardUserFormData)
  @Action(CloseRequest)
  closeShiftRequest(ctx: StateContext<EditRequestStateModel>): void {
    ctx.dispatch(new ResetUniversityDepartments());
    ctx.patchState({...DFLT_EDIT_SHIFT_REQUEST_STATE});
  }

  @Action(UpdateRequestDocuments)
  updateRequestDocuments(ctx: StateContext<EditRequestStateModel>, {documents}: UpdateRequestDocuments) {
    const request = this.store.selectSnapshot(EditRequestStateSelectors.getCurrentEditingRequest)!;
    ctx.dispatch(new UpdateRequest(this.updateDocumentsForRequest(request, documents)));
  }

  @Action(RemoveDocument)
  removeDocument(ctx: StateContext<EditRequestStateModel>, {documentId}: RemoveDocument): void {
    const {requestId} = ctx.getState();
    const request = this.store.selectSnapshot(EditRequestStateSelectors.getCurrentEditingRequest);

    if (!requestId || !request) {
      return;
    }

    this.documentsRestService
      .deleteAttachment(
        requestId,
        documentId,
        RequestsOverviewStateSelectors.getS3BucketType(request.requestType)!,
        'response'
      )
      .pipe(
        tap(() => {
          const updatedDocuments = request.requestDetail.documents.filter(d => d.id !== documentId);
          ctx.dispatch(new UpdateRequest(this.updateDocumentsForRequest(request, updatedDocuments)));
        }),
        catchError(error => this.errorMessageHandler.logAndIgnore(error)),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }

  @Action(DownloadAttachmentDocument)
  downloadAttachment(
    _ctx: StateContext<EditRequestStateModel>,
    {requestId, document, requestType}: DownloadAttachmentDocument
  ): Observable<Blob> {
    return this.documentsRestService
      .downloadAttachment(requestId, document.id, RequestsOverviewStateSelectors.getS3BucketType(requestType)!)
      .pipe(
        tap(blob => this.documentService.downloadFile(blob, document.name)),
        takeUntilDestroyed(this.destroyRef)
      );
  }

  @Action(DownloadResponseDocument)
  downloadResponse(
    _ctx: StateContext<EditRequestStateModel>,
    {requestId, document, requestType}: DownloadResponseDocument
  ): Observable<Blob> {
    return this.documentsRestService
      .downloadResponse(requestId, document.id, RequestsOverviewStateSelectors.getS3BucketType(requestType)!)
      .pipe(
        tap(blob => this.documentService.downloadFile(blob, document.name)),
        takeUntilDestroyed(this.destroyRef)
      );
  }

  @Action(FetchDivisionSchedule)
  fetchDivisionSchedule(ctx: StateContext<EditRequestStateModel>): Observable<DivisionScheduleDto[]> {
    const {requestId, divisionSchedules} = ctx.getState();
    const request = this.store.selectSnapshot(RequestsStateSelectors.getRequestByIdFn)(requestId!);
    if (!request) {
      throw new Error('Request must not be null!');
    }
    if (divisionSchedules.length > 0) {
      return EMPTY;
    }

    return this.dutiesRestService.getDivisionSchedule(request.dutyId).pipe(
      take(1),
      tap(schedules => ctx.patchState({divisionSchedules: schedules})),
      catchError(response => this.errorMessageHandler.logAndThrow(response)),
      takeUntilDestroyed(this.destroyRef)
    );
  }

  private loadRequestFormResources(
    ctx: StateContext<EditRequestStateModel>,
    requestDetail: RequestDtoRequestDetail
  ): Observable<void> {
    const resourceActionsToDispatch = [];
    if (EditRequestStateSelectors.isUniversityDetail(requestDetail)) {
      resourceActionsToDispatch.push(
        ...[
          new FetchUniversityNameCodeList(),
          new FetchDepartmentCodesForUniversity(requestDetail.university.university)
        ]
      );
    }
    resourceActionsToDispatch.push(new FetchReasonTypeCodeList());

    return ctx.dispatch(resourceActionsToDispatch);
  }

  private updateDocumentsForRequest(request: RequestDto, documents: DocumentDto[]): RequestDto {
    return {
      ...request,
      requestDetail: {
        ...request?.requestDetail,
        documents
      }
    };
  }
}
