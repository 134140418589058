import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, inject, OnInit} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MatTableModule} from '@angular/material/table';
import {RouterLink} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {Store} from '@ngxs/store';
import {MessageType} from '@shared/shared-module/components/enums/messageType';
import {MsaEmptyListBoxComponent} from '@shared/shared-module/components/msa-empty-list-box/msa-empty-list-box.component';
import {StatusPillComponent} from '@shared/shared-module/components/status-pill/status-pill.component';
import {SafeTranslatePipe} from '@shared/shared-module/pipes/safe-translate.pipe';
import {TranslateObjectPipe} from '@shared/shared-module/pipes/translate-object.pipe';
import {readStoreSignal} from '@shared/shared-module/utils/store.utils';
import {trackById} from '@shared/shared-module/utils/tracking.utils';
import {MsaActionsMenuComponent} from '../../../../../../src/app/shared-module/components/msa-actions-menu/msa-actions-menu.component';
import {MsaContentNoticeComponent} from '../../../../../../src/app/shared-module/components/msa-content-notice/msa-content-notice.component';
import {RequestState, RequestType} from '../../core/api/generated/msa-admin-query';
import {FetchAllRequests} from '../../stores/actions/requests.state.actions';
import {RequestsOverviewStateSelectors} from '../../stores/selectors/requests-overview.state.selectors';
import {RequestsStateSelectors} from '../../stores/selectors/requests.state.selectors';

@Component({
  selector: 'app-requests',
  templateUrl: './requests.component.html',
  styleUrl: './requests.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    MatMenuModule,
    MatTableModule,
    MatIconModule,
    MatButtonModule,
    MsaEmptyListBoxComponent,
    TranslateModule,
    StatusPillComponent,
    RouterLink,
    TranslateObjectPipe,
    SafeTranslatePipe,
    MsaContentNoticeComponent,
    MsaActionsMenuComponent
  ]
})
export class RequestsComponent implements OnInit {
  private store = inject(Store);

  errorMessage = readStoreSignal(RequestsStateSelectors.slices.errorMessage)!;
  requestViews = readStoreSignal(RequestsOverviewStateSelectors.getRequestViews)!;

  displayedColumns: string[] = ['type', 'duty', 'from', 'submittedAt', 'state', 'action'];
  message = MessageType;

  readonly trackById = trackById;

  ngOnInit(): void {
    this.store.dispatch(new FetchAllRequests());
  }

  protected readonly RequestType = RequestType;
  protected readonly RequestState = RequestState;
}
