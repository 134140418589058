import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  computed,
  effect,
  inject,
  input,
  OnInit
} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {TranslateModule} from '@ngx-translate/core';
import {FormStepperNavigationComponent} from '@shared/shared-module/components/form-stepper-navigation/form-stepper-navigation.component';
import {MsaFileUploadComponent} from '@shared/shared-module/components/msa-file-upload/msa-file-upload.component';
import {MsaSaveDraftComponent} from '@shared/shared-module/components/msa-save-draft/msa-save-draft.component';
import {RequiredFieldsNoticeComponent} from '@shared/shared-module/components/required-fields-notice/required-fields-notice.component';
import {DEFAULT_DEBOUNCE_TIME_MS} from '@shared/shared-module/config/rxjs.config';
import {MarkRequiredDirective} from '@shared/shared-module/directives/mark-required.directive';
import {SafeTranslateDirective} from '@shared/shared-module/directives/safe-translate.directive';
import {TranslationString} from '@shared/shared-module/utils/translation.utils';
import {noWhitespaceValidator} from 'projects/admin-query/src/app/validators/whitespace.validator';
import {debounceTime, tap} from 'rxjs';
import {DutyContextComponent} from '../../../../components/duty-context/duty-context.component';
import {RequestContextComponent} from '../../../../components/request-context/request-context.component';
import {DocumentDto, RequestType} from '../../../../core/api/generated/msa-admin-query';
import {RequestDocumentUploadService} from '../../../../services/request-document-upload.service';
import {RequestIncompleteNoticeComponent} from '../request-incomplete-notice/request-incomplete-notice.component';
import {StepEditRequestComponent} from '../step-edit-request-component/step-edit-request.component';

interface DetailedExplanationForm {
  detailedExplanation: FormControl<string>;
  uploadDocument: FormControl<string[]>;
}

@Component({
  selector: 'msa-step-detailed-explanation',
  templateUrl: './step-detailed-explanation.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    FormsModule,
    MatInputModule,
    FormStepperNavigationComponent,
    DutyContextComponent,
    MarkRequiredDirective,
    RequiredFieldsNoticeComponent,
    MsaFileUploadComponent,
    RequestIncompleteNoticeComponent,
    MsaSaveDraftComponent,
    RequestContextComponent,
    SafeTranslateDirective
  ],
  providers: [RequestDocumentUploadService]
})
export class StepDetailedExplanationComponent extends StepEditRequestComponent implements OnInit {
  public reasonHintText = input.required<TranslationString>();

  form = new FormGroup<DetailedExplanationForm>({
    detailedExplanation: new FormControl('', {
      nonNullable: true,
      validators: [Validators.required, noWhitespaceValidator(), Validators.minLength(5), Validators.maxLength(500)]
    }),
    uploadDocument: new FormControl([], {
      nonNullable: true,
      validators: [Validators.required, Validators.minLength(1)]
    })
  });

  public requestDocumentUploadService = inject(RequestDocumentUploadService);
  private changeDetectorRef = inject(ChangeDetectorRef);

  documents = computed(() => this.request().requestDetail.documents);
  pendingUploads$ = this.requestDocumentUploadService.hasPendingUploads$;

  public s3BucketType = computed(() =>
    this.request().requestType === RequestType.Shift || this.request().requestType === RequestType.Reconsideration
      ? 'PISA'
      : 'MILO'
  );

  constructor() {
    super();
    this.form.valueChanges
      .pipe(
        debounceTime(DEFAULT_DEBOUNCE_TIME_MS),
        tap(values =>
          this.action.emit({type: 'updateUserFormData', payload: {reason: values.detailedExplanation?.trim()}})
        ),
        takeUntilDestroyed()
      )
      .subscribe();

    effect(() => {
      this.form.controls.uploadDocument.patchValue(this.request().requestDetail.documents.map(d => d.id));

      // somehow component does not update if values do not change
      this.changeDetectorRef.detectChanges();
    });
  }

  ngOnInit(): void {
    this.form.patchValue({detailedExplanation: this.request().requestDetail.reason});
  }

  onNext(): void {
    this.action.emit({type: 'updateReason', payload: {reason: this.form.value.detailedExplanation!.trim()}});
  }

  onSaveClick() {
    this.action.emit({type: 'saveDraft'});
  }

  removeDocument(documentId: string): void {
    this.action.emit({type: 'removeAttachment', payload: documentId});
  }

  downloadDocument(document: DocumentDto) {
    this.action.emit({type: 'downloadAttachment', payload: document});
  }

  protected readonly RequestType = RequestType;
}
